<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center px-4">
        <div>
          <a href="/">
            <img src="../assets/img/eeia-logo.png" alt="" class="img-fluid" />
          </a>
        </div>
      </div>
      <!-- <h1 class="h2 mt-4 mb-3 text-center">
        École d'Été sur l'Intélligence Artificielle (EEIA)
      </h1> -->

      <h3 class="h3 mb-4 mt-4 text-center">
        <u>Vérification de votre adresse email</u>
      </h3>

      <div class="text-center img-wrapper">
        <img src="../assets/img/email.png" class="img-fluid tada-effect" />
      </div>

      <p class="fs-3" style="text-align: justify;">
        Un email vous a été envoyé à l'adresse <i style="overflow-wrap: break-word;">{{ email }}</i> juste après
        votre inscription. Veuillez consulter votre boite email puis cliquez sur
        le bouton de vérification.
      </p>

      <p class="">
        <u class="text-danger fw-bold fs-5">Notez bien</u> :
        Si vous ne recevez pas l'email de confirmation, consultez votre dossier de spams pour vérifier que le message envoyé ne s'y trouve pas. 
        <!-- Vous pouvez également ajouter l'adresse email <b>ne-pas-repondre.eeia@beninexcellence.org</b>
        à votre carnet d'adresses, puis renvoyer à nouveau l'email. -->
      </p>

      <p>
        <ul class="resend">
          <li>
            Vous pouvez renvoyer l'email en
        <button
          type="button"
          @click="resend()"
          class="btn btn-sm btn-outline-primary"
          id="resend-mail"
        >
          cliquant ici
        </button>
          </li>
          <li>
            Ou vous pouvez vous reconnecter en
        <button
          type="button"
          @click="reconnect()"
          class="btn btn-sm btn-outline-primary"
        >
          cliquant ici
        </button>
          </li>
        </ul>
      </p>

      <div class="text-center fs-6 mt-5">
        <img src="../assets/img/logo_be_vallet_pnud.svg" height="100" alt=""  />
        <p class="mb-1 text-muted">
          &copy; Bénin Excellence 2021–<span id="currentYear">2022</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="#" @click="reconnect()">Aller sur la page de connexion</a>
            </li>
            <li class="list-inline-item">
              <router-link to="/cgu">Les conditions d'utilisations</router-link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "EmailVerification",
  inject: [
    "ToastSuccess",
    "AlertError",
    "currentYearUrl",
    "ShowLoader",
    "HideLoader",
    "currentYearUrl"
  ],
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    // localStorage.setItem("email_verified", false);
    localStorage.setItem("session_expired", "false");

    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
        localStorage.removeItem("email");
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
        localStorage.removeItem("email");
      });

    this.ShowLoader();
    let url = window.location.search;
    let url_params = new URLSearchParams(url);
    if (url_params.has("status")) {
      if (url_params.get("status") == "fail") {
        this.AlertError.fire({
          title: "Echec !",
          html: "Désolé, une erreur s'est produite. <br>Le lien de vérification est invalide ou est déja expiré.",
        });
        // remove the parameter to prevent dhowing the popup when reloading the page
        // history.replaceState({}, "email-not-verified", "email-not-verified")
      } else if (url_params.get("status") == "success") {
        this.ToastSuccess.fire({
          title: "Votre adresse email a été vérifié avec succès!",
        });
        localStorage.setItem("email_verified", true);
        if (localStorage.getItem("email_verified")) {
          this.$router.push({ name: "Home" });
        }
      }
      this.HideLoader();
    } else {
      this.HideLoader();
    }
  },
  data() {
    return {
      email: localStorage.getItem("email"),
    };
  },
  methods: {
    resend() {
      this.$http
        .get("email/resend")
        .then(() => {
          this.ToastSuccess.fire({
            title: "Email renvoyé !",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.AlertError.fire({
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
        });
    },
    reconnect() {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      this.$router.push("login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #446ead !important;
  font-size: 20px;
}

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.form-signin {
  padding: 15px;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  background: white;
  border-radius: 8px;
}

ul.resend {
  padding-left: 3rem;
}

.img-wrapper {
  width: 130px;
  margin: auto;
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.8s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.tada-effect {
  animation-duration: 1s;
  animation-name: tada;
  animation-iteration-count: 2;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>
