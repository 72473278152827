<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-sm-5 pt-3">
      <div class="col">
        <div class="alert alert-info text-center fs-3" role="alert">
          Bienvenue sur la plateforme de candidature à l'EEIA 2023 !
        </div>
        <!-- <div class="alert alert-danger" role="alert">
          Les candidatures sont closes.
        </div> -->
        <div class="col mt-5 mb-4">
          <h4 class="section-title text-uppercase">SECTION INFO</h4>
        </div>
        <div
          class="card border-info mb-3"
          v-for="info in informations"
          :key="info.id"
        >
          <div class="card-body ql-editor">
            <p class="card-text" v-html="info.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  mounted() {
    if (localStorage.getItem("candidature_step")) {
      this.preselected = localStorage.getItem("candidature_step") == 4;
      this.selected = localStorage.getItem("candidature_step") == 5;
    }

    this.getInformations();
    this.getUser();
  },
  data() {
    return {
      informations: {},
    };
  },
  methods: {
    getInformations() {
      this.$http.get("announcements").then((result) => {
        this.informations = result.data;
      });
    },
    getUser() {
      this.$http.get("users/000").then((result) => {
        localStorage.setItem("candidature_step", result.data.step);
      });
    },
  },
};
</script>

<style scoped>

@import '../assets/styles/quill.core.css';
.container {
  background-color: #80808096;
  /* min-height: 50vh; */
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
