<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form @submit="handleSubmit($event, onSubmit)" class="row">
        <div class="col">
          <!-- //* AUTO-EVALUATION -->

          <div class="row mb-2">
            <div class="col mt-5">
              <h2 class="section-title text-uppercase">AUTO-EVALUATION</h2>
            </div>
          </div>

          <div
            class="alert alert-warning"
            role="alert"
            v-if="!hasAlreadySubmited"
          >
            ATTENTION ! Une fois le test D'<i>AUTO-EVALUATION</i> soumis, plus
            aucune modification ne sera possible ensuite.
          </div>

          <div
            class="alert alert-warning"
            role="alert"
            v-if="hasAlreadySubmited"
          >Vous ne pouvez plus faire l'auto-évaluation !
          </div>

          <div class="row mb-3" v-if="!hasAlreadySubmited">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title h4">Informatique</h5>
                  <div class="container">
                    <div class="row">
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="informatique_1" class="form-label"
                          >Utilisez-vous régulièrement internet ?</label
                        >
                        <Field
                          name="informatique_1"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="informatique_1"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="informatique_1"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="informatique_2" class="form-label"
                          >Maitrisez-vous l’utilisation des smartphones ou
                          tablettes ?</label
                        >
                        <Field
                          name="informatique_2"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="informatique_2"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="informatique_2"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="informatique_3" class="form-label"
                          >Connaissez-vous la différence entre un fichier PDF et
                          un fichier JPEG ?</label
                        >
                        <Field
                          name="informatique_3"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="informatique_3"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="informatique_3"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="informatique_4" class="form-label"
                          >Avez-vous jamais programmé ?</label
                        >
                        <Field
                          name="informatique_4"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="informatique_4"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="informatique_4"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3" v-if="!hasAlreadySubmited">
            <div class="col">
              <div class="card striped-card">
                <div class="card-body">
                  <h5 class="card-title h4">Mathématiques</h5>
                  <div class="container">
                    <div class="row">
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="mathematiques_1" class="form-label"
                          >Pouvez-vous placer des points dans un repère
                          orthonormé (abscisses et ordonnées) ?</label
                        >
                        <Field
                          name="mathematiques_1"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="mathematiques_1"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="mathematiques_1"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="mathematiques_2" class="form-label"
                          >Connaissez-vous le théorème de Pythagore ?</label
                        >
                        <Field
                          name="mathematiques_2"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="mathematiques_2"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="mathematiques_2"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="mathematiques_3" class="form-label"
                          >Vous souvenez-vous de l’équation d’une droite
                          ?</label
                        >
                        <Field
                          name="mathematiques_3"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="mathematiques_3"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="mathematiques_3"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div class="mb-4 col-lg-3 col-md-6 col-sm-12 col-12">
                        <label for="mathematiques_4" class="form-label"
                          >Connaissez-vous les fonctions log et exponentielle
                          ?</label
                        >
                        <Field
                          name="mathematiques_4"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="mathematiques_4"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="mathematiques_4"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3" v-if="!hasAlreadySubmited">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title h4">Statistiques</h5>
                  <div class="container">
                    <div class="row">
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="statistiques_1" class="form-label"
                          >Savez-vous calculer une moyenne ?</label
                        >
                        <Field
                          name="statistiques_1"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="statistiques_1"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="statistiques_1"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="statistiques_2" class="form-label"
                          >Savez-vous interpréter un diagramme en bâton ?</label
                        >
                        <Field
                          name="statistiques_2"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="statistiques_2"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="statistiques_2"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="statistiques_3" class="form-label"
                          >Pouvez-vous calculer des pourcentages à partir de
                          données d’enquête ?</label
                        >
                        <Field
                          name="statistiques_3"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="statistiques_3"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="statistiques_3"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div class="mb-4 col-lg-3 col-md-6 col-sm-12 col-12">
                        <label for="statistiques_4" class="form-label"
                          >Connaissez-vous la définition de l’espérance
                          mathématique ?</label
                        >
                        <Field
                          name="statistiques_4"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="statistiques_4"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="statistiques_4"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3" v-if="!hasAlreadySubmited">
            <div class="col">
              <div class="card striped-card">
                <div class="card-body">
                  <h5 class="card-title h4">Physique</h5>
                  <div class="container">
                    <div class="row">
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="physique_1" class="form-label"
                          >Connaissez-vous la différence entre montage en série
                          et montage en parallèle ?</label
                        >
                        <Field
                          name="physique_1"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="physique_1"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="physique_1"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="physique_2" class="form-label"
                          >Pouvez-vous calculer la vitesse moyenne à partir de
                          la distance et du temps ?</label
                        >
                        <Field
                          name="physique_2"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="physique_2"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="physique_2"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div
                        class="
                          mb-4
                          col-lg-3 col-md-6 col-sm-12 col-12
                          border-right-separator
                        "
                      >
                        <label for="physique_3" class="form-label"
                          >Savez-vous si le bois sec est un conducteur
                          électrique ou un isolant ?</label
                        >
                        <Field
                          name="physique_3"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="physique_3"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="physique_3"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                      <div class="mb-4 col-lg-3 col-md-6 col-sm-12 col-12">
                        <label for="physique_4" class="form-label"
                          >Savez-vous dériver une fonction ?</label
                        >
                        <Field
                          name="physique_4"
                          label="catégorie socioprofessionnelle"
                        >
                          <evaluation-radio
                            name="physique_4"
                            value="Oui"
                            label="Oui"
                          ></evaluation-radio>
                          <evaluation-radio
                            name="physique_4"
                            value="Non"
                            label="Non"
                          ></evaluation-radio>
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <button
              class="btn btn-lg btn-primary"
              @click="$emit('to-previous')"
              type="button"
            >
              Précédent
            </button>
            <button
              class="btn btn-lg btn-primary ms-2"
              id="submit-button"
              type="submit"
              v-if="!hasAlreadySubmited"
            >
              Suivant
            </button>
            <button
              class="btn btn-lg btn-primary ms-2"
              id="submit-button"
              type="button"
              v-else @click="$emit('to-next')"
            >
              Suivant
            </button>
          </div>

          <div class="row mb-4">
            <span class="text-danger d-none" id="form-invalid-error"
              ><i v-if="!meta.valid">
                Une ou plusieurs erreurs sont présentes dans le formulaire.</i
              ></span
            >
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import EvaluationRadio from "../components/EvaluationRadio.vue";

defineRule("required", required);

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "Evaluation",
  props: {
    previousData: Object,
    step: Number,
  },
  inject: ["ToastSuccess", "ToastError", "currentYearUrl", "AlertSuccess"],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    EvaluationRadio,
  },
  mounted() {
    // localStorage.setItem("currentStep", 2);
    window.scrollTo(0, 0);
  },
  data() {
    let schema = {};

    if (!this.hasAlreadySubmited) {
      schema = {
        informatique_1: "required",
        informatique_2: "required",
        informatique_3: "required",
        informatique_4: "required",
        mathematiques_1: "required",
        mathematiques_2: "required",
        mathematiques_3: "required",
        mathematiques_4: "required",
        statistiques_1: "required",
        statistiques_2: "required",
        statistiques_3: "required",
        statistiques_4: "required",
        physique_1: "required",
        physique_2: "required",
        physique_3: "required",
        physique_4: "required",
      };
    } else {
      schema = {};
    }

    return {
      schema,
      informatique_1: this.previousData.informatique_1,
      informatique_2: this.previousData.informatique_2,
      informatique_3: this.previousData.informatique_3,
      informatique_4: this.previousData.informatique_4,
      mathematiques_1: this.previousData.mathematiques_1,
      mathematiques_2: this.previousData.mathematiques_2,
      mathematiques_3: this.previousData.mathematiques_3,
      mathematiques_4: this.previousData.mathematiques_4,
      statistiques_1: this.previousData.statistiques_1,
      statistiques_2: this.previousData.statistiques_2,
      statistiques_3: this.previousData.statistiques_3,
      statistiques_4: this.previousData.statistiques_4,
      physique_1: this.previousData.physique_1,
      physique_2: this.previousData.physique_2,
      physique_3: this.previousData.physique_3,
      physique_4: this.previousData.physique_4,
    };
  },
  methods: {
    onSubmit(values, actions) {
      this.beforeSendPromise(values).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.$emit("to-next");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 418) {
              this.AlertError.fire({
                icon: "error",
                title: "Délai dépassé !",
                html: "Vous ne pouvez plus soumettre votre dossier.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values) {
      return new Promise(function (resolve) {
        let formData = new FormData();
        formData.append("step", 5);
        formData.append("informatique_1", values.informatique_1);
        formData.append("informatique_2", values.informatique_2);
        formData.append("informatique_3", values.informatique_3);
        formData.append("informatique_4", values.informatique_4);
        formData.append("mathematiques_1", values.mathematiques_1);
        formData.append("mathematiques_2", values.mathematiques_2);
        formData.append("mathematiques_3", values.mathematiques_3);
        formData.append("mathematiques_4", values.mathematiques_4);
        formData.append("statistiques_1", values.statistiques_1);
        formData.append("statistiques_2", values.statistiques_2);
        formData.append("statistiques_3", values.statistiques_3);
        formData.append("statistiques_4", values.statistiques_4);
        formData.append("physique_1", values.physique_1);
        formData.append("physique_2", values.physique_2);
        formData.append("physique_3", values.physique_3);
        formData.append("physique_4", values.physique_4);

        resolve(formData);
      });
    },
  },
  computed: {
    hasAlreadySubmited() {
      // let stepp = 2;
      if (this.step >= 5) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-right-separator {
  border-right: 1px solid #80808047;
}

.striped-card {
  background-color: #7fbcd921;
}

.section-title {
  border-bottom: 3px solid #3490dc;
}

/* ============ mobile view ============ */
@media all and (max-width: 991px) {
  .border-right-separator {
    border-right: unset;
  }
}
/* ============ mobile view .end// ============ */

/** #7fbcd9 */
</style>
