import { createApp } from 'vue'
import './assets/styles/index.css'
import 'bootstrap'
import './assets/styles/custom.scss';
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

// import Particles from "particles.vue3";
import Swal from 'sweetalert2'

import router from './router'

// router config
//Check login and redirect
router.beforeEach((to, from, next) => {
    // window.scrollTo(0, 0)
    if (to.matched.some(route => route.meta.requiresAuth)) {
        let token = localStorage.getItem("token");

        if (token && token != "undefined") {
            let email_verified = localStorage.getItem("email_verified");
            if (email_verified == "true") {
                next();
            } else {
                next({ path: "/verify-email" });
            }
        } else {
            next({ path: "/login" });
        }
    } else if (to.matched.some(route => route.name === "Login")) {
        let token = localStorage.getItem("token");

        if (token && token != "undefined") {
            next({ name: "Home" });
        } else {
            next();
        }
    } else {
        next();
    }
    
    fetch(window.location.origin + "?v=" + Math.floor(Math.random() * 1331))
        .then((result) => {
            result.text().then(function (text) {
                let prev = document.querySelectorAll('link')[5].getAttribute('href')

                const regex = /href="\/js\/app\.([a-z0-9]+)/;
                const regex2 = /js\/app\.([a-z0-9]+)/;
                const match1 = prev.match(regex2);
                const match2 = text.match(regex);
                const extractedValue1 = match1 ? match1[1] : null;
                const extractedValue2 = match2 ? match2[1] : null;
                
                if (extractedValue1 == extractedValue2) {
                    // console.log(extractedValue1, extractedValue2)
                    //console.log('v=')
                } else {
                    // console.log('v!')
                    // console.log(extractedValue1, extractedValue2)
                    window.location.reload();
                }
            });
        })

});


const ToastSuccess = Swal.mixin({
    toast: true,
    icon: "success",
    title: "Opération réussie!",
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 4000,
    showCloseButton: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const ToastError = Swal.mixin({
    toast: true,
    icon: "error",
    title: "Une erreur s'est produite!",
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 4000,
    showCloseButton: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const ToastWarning = Swal.mixin({
    toast: true,
    icon: "warning",
    title: "Attention !...",
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 4000,
    showCloseButton: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const AlertSuccess = Swal.mixin({
    toast: false,
    icon: "success",
    title: "Succès!",
    showConfirmButton: true,
    confirmButtonColor: '#0066b6',
    showCloseButton: true,
})

const AlertError = Swal.mixin({
    toast: false,
    icon: "error",
    title: "Une erreur s'est produite!",
    showConfirmButton: false,
    showCloseButton: true,
})

const AlertWarning = Swal.mixin({
    toast: false,
    icon: "warning",
    title: "Attention!",
    showConfirmButton: false,
    showCloseButton: true,
})

const ShowLoader = (text = 'Traitement en cours') => {
    document.getElementById("appLoader").classList.add('d-block')
    document.getElementById("appLoader").classList.remove('d-none')
    document.getElementById("loaderText").textContent = text + ' ...'
}

const HideLoader = () => {
    document.getElementById("appLoader").classList.remove('d-block')
    document.getElementById("appLoader").classList.add('d-none')
}

const currentYearUrl = process.env.VUE_APP_API_ENDPOINT + "/api/current-year"

//axios config
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
    ? "Bearer " + localStorage.getItem("token")
    : "";
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT + "/api/candidate";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    ShowLoader()
    return config;
});

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        HideLoader()
        return response;
    },
    function (error) {
        HideLoader()
        if (error.response && error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("candidature_step");
            localStorage.setItem("session_expired", "true");
            router.push("login");
        }
        if (error.toJSON().message === "Network Error") {
            ToastError.fire({
                title:
                    "Pas de connexion au serveur. Vérifier votre connexion internet et réessayer."
            });
        }
        return Promise.reject(error);
    }
);

const app = createApp(App).use(router)
app.use(VueAxios, axios)

// app.use(Particles)
app.provide('ToastSuccess', ToastSuccess)
app.provide('ToastError', ToastError)
app.provide('ToastWarning', ToastWarning)
app.provide('AlertError', AlertError)
app.provide('AlertWarning', AlertWarning)
app.provide('AlertSuccess', AlertSuccess)
app.provide('currentYearUrl', currentYearUrl)
app.provide('ShowLoader', ShowLoader)
app.provide('HideLoader', HideLoader)
// app.provide('authBaseUrl', authBaseUrl)
app.mount('#app')
