<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center px-4">
        <div>
          <a href="/">
            <img src="../assets/img/eeia-logo.png" alt="" class="img-fluid" />
          </a>
        </div>
      </div>
      <!-- <h1 class="h2 mt-4 mb-3 text-center">
        École d'Été sur l'Intélligence Artificielle (EEIA)
      </h1> -->

      <h3 class="h3 mb-4 mt-4 text-center"><u>Page de connexion</u></h3>

      <div class="container-fluid">
        <div class="row justify-content-center">
          <VeeForm
            class="col-sm-8"
            v-slot="{ handleSubmit }"
            :validation-schema="schema"
            as="div"
          >
            <div
              class="alert alert-danger d-none"
              id="alertError"
              role="alert"
            ></div>
            <form @submit="handleSubmit($event, onSubmit)">
              <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Entrer votre email ici."
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    id="emailInput"
                    v-bind="field"
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                  />
                </Field>
                <ErrorMessage name="email" as="div" class="invalid-feedback" />
              </div>
                <label for="password" class="form-label">Mot de passe</label>
              <div class="mb-3 input-group">
                <Field
                  name="password"
                  label="mot de passe"
                  placeholder="Entrer votre mot de passe ici."
                  v-slot="{ field, errorMessage }"
                >
                  <input
                    :type="[showPassword ? 'text' : 'password']"
                    id="password"
                    v-bind="field"
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                    aria-describedby="btnGroupAddon1"
                  />
                  <div class="input-group-text" id="btnGroupAddon1" title="Afficher/Cacher">
                    <font-awesome-icon
                      icon="eye"
                      v-if="!showPassword"
                      @click="showPassword = !showPassword"
                    />
                    <font-awesome-icon
                      icon="eye-slash"
                      v-if="showPassword"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                </Field>
                <ErrorMessage
                  name="password"
                  as="div"
                  class="invalid-feedback"
                />
              </div>
              <button class="w-100 btn btn-lg btn-primary" type="submit">
                Se connecter
              </button>
              <!-- <p class="text-center my-2">OU</p>
              <button class="w-100 btn btn-lg btn-primary" type="button">
                S'inscrire
              </button> -->
            </form>
          </VeeForm>
        </div>
      </div>

      <div class="text-center fs-6 mt-5">
        <img src="../assets/img/logo_be_vallet_pnud.svg" height="100" alt=""  />
        <p class="mb-1 text-muted">
          &copy; Bénin Excellence 2021–<span id="currentYear">2022</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link to="/register"
                >Aller sur la page d'inscription</router-link
              >
            </li>
            <li class="list-inline-item">
              <router-link to="/forget-password"
                >J'ai oublié mon mot de passe</router-link
              >
            </li>
            <li class="list-inline-item">
              <router-link to="/cgu">Les conditions d'utilisations</router-link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faEye, faEyeSlash);

defineRule("required", required);
defineRule("email", email);

defineRule("custom_email", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // console.log(value.trim())
  // Check if email
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!pattern.test(value.trim())) {
    return "Le champ email doit être une adresse e-mail valide";
  }
  return true;
});

defineRule("min", min);

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

export default {
  name: "Login",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
    FontAwesomeIcon,
  },
  props: {
    msg: String,
  },
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    this.sessionExpired();
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });
  },
  data() {
    const schema = {
      email: "required|custom_email",
      password: "required",
    };
    return {
      schema,
      showPassword: false
    };
  },
  methods: {
    onSubmit(values) {
      // Submit values to API...
      // alert(JSON.stringify(values, null, 2));

      this.$http({
        url: "login",
        method: "post",
        data: {
          email: values.email,
          password: values.password,
        },
      })
        .then((response) => {
          // this.ToastSuccess.fire({
          //   title: "Signed in successfully",
          // });

          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("candidature_step", response.data.step);
          localStorage.setItem("email_verified", response.data.email_verified);
          localStorage.setItem("firstName", response.data.first_name);
          localStorage.setItem("lastName", response.data.last_name);
          localStorage.setItem("userId", response.data.id);

          this.$http.defaults.headers.common["Authorization"] =
            localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : "";

          if (
            localStorage.getItem("session_expired") &&
            localStorage.getItem("session_expired") == "true"
          ) {
            localStorage.setItem("session_expired", "false");
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.has("redirect")) {
              window.location.replace(urlParams.get("redirect"));
            } else {
              this.$router.push({ name: "Home" });
            }
          } else {
            this.$router.push({ name: "Home" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              Object.prototype.hasOwnProperty.call(error.response.data, "error")
            ) {
              if (error.response.data.error == "Unauthenticated") {
                this.ToastError.fire({
                  title: "Échec de l'authentification.",
                });
                document
                  .getElementById("alertError")
                  .classList.remove("d-none");

                document.getElementById("alertError").textContent = "";
                document
                  .getElementById("alertError")
                  .append(
                    "Votre Email ou votre mot de passe est incorrect. Veuillez réessayer."
                  );
                document
                  .getElementById("alertError")
                  .classList.remove("d-none");
              }
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          } else {
            this.ToastError.fire({
              title: "Une erreur s'est produite, veuillez réessayer.",
            });
          }
        });
    },
    sessionExpired() {
      if (localStorage.getItem("session_expired")) {
        if (localStorage.getItem("session_expired") == "true") {
          document.getElementById("alertError").textContent = "";
          document
            .getElementById("alertError")
            .append("Veuillez vous reconnecter. Votre session a expiré.");
          document.getElementById("alertError").classList.remove("d-none");
        } else {
          document.getElementById("alertError").textContent = "";
          document.getElementById("alertError").classList.remove("d-block");
          document.getElementById("alertError").classList.add("d-none");
        }
      } else {
        document.getElementById("alertError").textContent = "";
        document.getElementById("alertError").classList.remove("d-block");
        document.getElementById("alertError").classList.add("d-none");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #446ead !important;
  font-size: 20px;
}

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.form-signin {
  padding: 15px;
  width: 100%;
  background: white;
  border-radius: 8px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.input-group-text svg {
  cursor: pointer;
}

</style>
